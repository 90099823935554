import { getFromLocalStorage } from '../../Utils/localStorage';
import AnalyticsService from './AnalyticsService';

const analyticsService = new AnalyticsService();

export const trackEvent = (eventName, properties = {}) => {
  const language = 'en';

  const userData = getFromLocalStorage('user');
  const influencerData = getFromLocalStorage('influencer');

  // const dataToUse = Object.keys(userData).length === 0 ? influencerData : userData;
  let dataToUse = {}
  if(userData && userData.userLoggedIn === true) {
    dataToUse = userData
  }

  if(influencerData && influencerData.isLoggedIn === true) {
    dataToUse = influencerData
  }

  const {accessToken, refreshToken, user, influencerPageLoadedData,  ...rest} = Object.keys(dataToUse).length > 0 
    ? dataToUse 
    : {};

  const eventData = {
    event: eventName,
    platform: 'web',
    accept_language: language,
    online_status: navigator.onLine,
    ...(Object.keys(rest).length > 0 && {...rest}),
    ...properties
  };

  analyticsService.trackEvent(eventName, eventData);
};

export default analyticsService;