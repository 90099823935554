export const getFromLocalStorage = (key) => {
  try {
    const data = localStorage.getItem(key);
    if (!data) return {}

    return data.startsWith('{') || data.startsWith('[') ? JSON.parse(data) : data;
  } catch (error) {
    console.error(`Error retrieving key "${key}" from localStorage:`, error);
    return {}; 
  }
};