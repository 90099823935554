import { signInWithPopup } from "@firebase/auth";
import { Button, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userSignInSignUpAPi } from "../../Api/InfluencerProfileApis";
import Snackbarpopup from "../../Common/SnackBarPopup";
import ForegetPassword from "../../Pages/ForegetPassword";
import { auth, provider } from "../../Utils/firebase";
import setAuthToken from "../../Utils/setAuthToken";
import { validateEmail, validatePassword } from "../../Utils/validation";
import ButtonField from "./ButtonField";
import { ReactComponent as GoogleIcon } from "../../assets/icons/GoogleIcon.svg";
import "./SigninCard.css";
import { useQuery } from "../../Utils/hooks";
import {
  generateEmailOtpApi,
  verifyOtpApi,
} from "../../Api/AuthenticationApis";
import { trackEmailOtpStatus, trackEmailOtpVerifyStatus, trackLoginBtnClick, trackLoginFailed, trackLoginFormImpression, trackLoginSuccess } from "../../Lib/Analytics/TrackEvents/LoginFlow";

const SigninCard = React.memo(({ close, showTitle = true }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formType, setFormType] = useState("LOGIN");
  // const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const [userData, setUserData] = useState();

  // const handleSignin = async (gAuthObj) => {
  //   Snackbarpopup("Please wait...", "warning", dispatch);
  //   let userData = {};
  //   if (gAuthObj) {
  //     userData = {
  //       email: gAuthObj.email,
  //       password: gAuthObj.password,
  //       influencerUsername: state.userReducer.influencerPageLoadedData.username,
  //     };
  //   } else {
  //     userData = {
  //       email,
  //       password,
  //       influencerUsername: state.userReducer.influencerPageLoadedData.username,
  //     };
  //   }
  //   const response = await userSignInSignUpAPi(userData);
  //   if (response.data.status) {
  //     setIsEmailVerified(response.data.savedUserData.isVerifiedEmail);
  //   }
  //   // if (response.data.status) {
  //   //   localStorage.setItem(
  //   //     "auth-token",
  //   //     JSON.stringify({
  //   //       accessToken: response.data.savedUserData.accessToken,
  //   //       refreshToken: response.data.savedUserData.refreshToken,
  //   //     })
  //   //   );
  //   //   setAuthToken();
  //   //   let userData = {
  //   //     userType: response.data.savedUserData.userType,
  //   //     email: response.data.savedUserData.email,
  //   //     accessToken: response.data.savedUserData.accessToken,
  //   //     refreshToken: response.data.savedUserData.refreshToken,
  //   //     id: response.data.savedUserData.id,
  //   //     isLoggedIn: false,
  //   //     influencer: response.data.savedUserData.influencer,
  //   //     subscriptionDetails: response.data.savedUserData.subscriptionDetails,
  //   //     user: response.data.savedUserData.user,
  //   //     userLoggedIn: true,
  //   //     logoutTimer: Date.now(),
  //   //   };
  //   //   dispatch({
  //   //     type: "USER_LOGIN",
  //   //     payload: {
  //   //       ...userData,
  //   //     },
  //   //   });
  //   //   localStorage.setItem("user", JSON.stringify(userData));

  //   //   dispatch({
  //   //     type: "DEAFULT_USER_LOGIN_REDIRECT",
  //   //   });
  //   //   Snackbarpopup(response.data.message, "success", dispatch);
  //   //   close();
  //   // } else {
  //   //   Snackbarpopup(response.data.message, "warning", dispatch);
  //   // }
  // };

  useEffect(()=> {
    trackLoginFormImpression('user', 'login')
  },[])

  const handleSignin = async (gAuthObj) => {
    Snackbarpopup("Please wait...", "warning", dispatch);
    let userData = {};

    if (gAuthObj) {
      setEmail(gAuthObj.email);
      userData = {
        email: gAuthObj.email,
        password: gAuthObj.password,
        influencerUsername: state.userReducer.influencerPageLoadedData.username,
      };
      trackLoginBtnClick(userData, 'user', 'sign_in_google');
    } else {
      userData = {
        email,
        password,
        influencerUsername: state.userReducer.influencerPageLoadedData.username,
      };
      const { password: _, ...rest } = userData;
      trackLoginBtnClick(rest, 'user', 'login');
    }
    const response = await userSignInSignUpAPi(userData);
    if (response.data.status) {
      localStorage.setItem(
        "auth-token",
        JSON.stringify({
          accessToken: response.data.savedUserData.accessToken,
          refreshToken: response.data.savedUserData.refreshToken,
        })
      );
      setAuthToken();
      let userData = {
        userType: response.data.savedUserData.userType,
        email: response.data.savedUserData.email,
        accessToken: response.data.savedUserData.accessToken,
        refreshToken: response.data.savedUserData.refreshToken,
        id: response.data.savedUserData.id,
        isLoggedIn: false,
        influencer: response.data.savedUserData.influencer,
        subscriptionDetails: response.data.savedUserData.subscriptionDetails,
        user: response.data.savedUserData.user,
        // userLoggedIn: true,
        logoutTimer: Date.now(),
      };
      // trigger login success event
      trackLoginSuccess({email: response.data.savedUserData.email, 
        userId: response.data.savedUserData.id,
        isVerifiedEmail: response.data.savedUserData.isVerifiedEmail
      },'user', 'login')
      if (response.data.savedUserData.isVerifiedEmail === false) {
        setIsOtpVisible(true);
        setUserData(userData);
        let mail;
        if (gAuthObj?.email) {
          mail = gAuthObj.email;
        } else {
          mail = email;
        }
        try {
          let response = await generateEmailOtpApi(mail);
          console.log({ response });
          trackEmailOtpStatus({email: response.data.savedUserData.email, 
            userId: response.data.savedUserData.id,
            isEmailSent: 'true'
          },'user', 'login')
        } catch (e) {
          Snackbarpopup("Error while generating email", "success", dispatch);
          trackEmailOtpStatus({email: response.data.savedUserData.email, 
            userId: response.data.savedUserData.id,
            isEmailSent: 'false'
          },'user', 'login')
        }
      } else {
        dispatch({
          type: "USER_LOGIN",
          payload: { ...userData, userLoggedIn: true },
        });

        localStorage.setItem(
          "user",
          JSON.stringify({ ...userData, userLoggedIn: true })
        );

        dispatch({
          type: "DEAFULT_USER_LOGIN_REDIRECT",
        });

        Snackbarpopup("Login successful!", "success", dispatch);
        close?.();
      }      
    } else {
      const loginType = gAuthObj ? 'sign_in_google' : 'login'
      trackLoginFailed({email: email, error: response.data.message},'user', loginType)
      Snackbarpopup(response.data.message, "warning", dispatch);
    }
  };

  const signInWithGoogle = async (e) => {
    const data = await signInWithPopup(auth, provider);
    let gAuthObject = {
      email: data.user.email,
      password: data.user.uid,
      social: true,
    };
    handleSignin(gAuthObject);
  };

  const query = useQuery();
  const viewType = query?.get("view-type");

  useEffect(() => {
    if (viewType === "reset-password") {
      setFormType("RESET");
    }
  }, [viewType]);

  const handleVerifyOtp = async () => {
    if (!/^\d{6}$/.test(otp)) {
      Snackbarpopup("OTP must be a 6-digit number.", "warning", dispatch);
      return;
    }
    let otpResponse;
    try {
      otpResponse = await verifyOtpApi({
        email: email,
        otp: otp,
      });
    } catch (e) {
      console.log(e);
      Snackbarpopup(otpResponse.data.message, "warning", dispatch);
    }
    if (otpResponse.data.status) {
      if (otpResponse.data.data.isVerifiedEmail === false) {
        return;
      }

      dispatch({
        type: "USER_LOGIN",
        payload: {
          ...userData,
          isVerifiedEmail: true,
          userLoggedIn: true,
        },
      });

      localStorage.setItem(
        "user",
        JSON.stringify({
          ...userData,
          isVerifiedEmail: true,
          userLoggedIn: true,
        })
      );

      dispatch({
        type: "DEAFULT_USER_LOGIN_REDIRECT",
      });

      Snackbarpopup("Login successful!", "success", dispatch);
      close?.();
      trackEmailOtpVerifyStatus({
            email: userData.email,
            userId: userData.id,
            isVerifiedEmail: 'true'
          },'user', 'login')
    } else {
      Snackbarpopup(
        otpResponse.data.message || "Invalid OTP.",
        "warning",
        dispatch
      );
       trackEmailOtpVerifyStatus({
            email: userData.email,
            userId: userData.id,
            isVerifiedEmail: 'false',
            error: otpResponse.data.message
          },'user', 'login')
    }
  };

  return (
    <>
      {showTitle && (
        <Typography className="profile-heading">Official.me</Typography>
      )}
      {formType === "LOGIN" ? (
        isOtpVisible ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "15px",
            }}
          >
            <span style={{ marginBottom: "15px", color: "gray" }}>
              An OTP has been sent to your email.
            </span>
            <TextField
              label="Enter OTP"
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 6 }}
              style={{ marginBottom: "20px" }}
            />
            <ButtonField
              buttonName={"Verify OTP"}
              handleClick={handleVerifyOtp}
            />
          </div>
        ) : (
          <div className="signin-content">
            <div className="modal-heading">
              <h3>Sign in/Sign up</h3>
            </div>
            {/* Email  */}
            <>
              <TextField
                label={"Username/ Email address"}
                type={"email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                fullWidth
              />

              {/* Password */}
              <TextField
                label={"Password"}
                type={"password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                fullWidth
              />
              <Link
                className="recover_passowrd"
                onClick={() => setFormType("FORGET")}
              >
                Forget password ?
              </Link>
              {/* Sign in Button */}
              <ButtonField
                buttonName={"Sign in/Sign up"}
                handleClick={() => {
                  if (!validatePassword(password)) {
                    Snackbarpopup(
                      "Password should have atleast 8 characters, 1 special character, 1 capital alphabet, 1 small alphabet and 1 digit.",
                      "warning",
                      dispatch
                    );
                  } else if (!validateEmail(email)) {
                    Snackbarpopup(
                      "Please check your email.",
                      "warning",
                      dispatch
                    );
                  } else {
                    handleSignin();
                  }
                }}
              />

              <p className="signin-card__text">
                By signing in you agree to our terms of use and privacy policy
              </p>

              <Typography align="center">OR</Typography>

              {/* Sign in with google */}
              <Button
                startIcon={<GoogleIcon />}
                variant="outlined"
                onClick={() => {
                  if (state.userReducer.userType !== "influencer") {
                    signInWithGoogle();
                  } else {
                    Snackbarpopup(
                      "You are already logged in as an Influencer",
                      "warning",
                      dispatch
                    );
                  }
                }}
              >
                Sign in with Google
              </Button>
            </>
          </div>
        )
      ) : (
        <ForegetPassword callback={setFormType} />
      )}
    </>
  );
})

export default SigninCard;
