import { trackEvent } from '../index';

const LOGIN_ANALYTICS_EVENTS = {
  login_form_impression: 'login_form_impression',
  login_btn_click: 'login_btn_click',
  login_sucess: 'login_sucess',
  login_failed: 'login_failed',
  login_email_otp_status: 'login_email_otp_status',
  login_email_otp_verify_status: 'login_email_otp_verify_status',
};

/*
user_type = 'influencer' | 'user'
type: 'signup' | 'signin' | 'login' | 'signin_google'
*/

export const trackLoginFormImpression = (user_type, type) => {
  trackEvent(LOGIN_ANALYTICS_EVENTS.login_form_impression, { user_type: user_type, type: type });
};

export const trackLoginBtnClick = (data, user_type, type) => {
  trackEvent(LOGIN_ANALYTICS_EVENTS.login_btn_click, { ...data, user_type: user_type, type: type });
};

export const trackLoginSuccess = (data, user_type, type) => {
  trackEvent(LOGIN_ANALYTICS_EVENTS.login_sucess, { ...data, user_type: user_type, type: type });
};

export const trackLoginFailed = (data, user_type, type) => {
  trackEvent(LOGIN_ANALYTICS_EVENTS.login_failed, { ...data, user_type: user_type, type: type });
};

export const trackEmailOtpStatus = (data, user_type, type) => {
  trackEvent(LOGIN_ANALYTICS_EVENTS.login_email_otp_status, { ...data, user_type: user_type, type: type });
};

export const trackEmailOtpVerifyStatus = (data, user_type, type) => {
  trackEvent(LOGIN_ANALYTICS_EVENTS.login_email_otp_verify_status, { ...data, user_type: user_type, type: type });
};