import { trackEvent } from '../index';

const PAYMENT_ANALYTICS_EVENTS = {
  payment_form_impression: 'payment_form_impression',
  payment_now_click: 'payment_btn_click',
  payment_sucess: 'payment_sucess',
  payment_failed: 'payment_failed',
  payment_verified: 'payment_verified',
};

// payment_type = bluswap_payment_link | 

export const trackPaymentImpression = () => {
  trackEvent(PAYMENT_ANALYTICS_EVENTS.payment_form_impression);
};

export const trackPaymentBtnClick = (data, payment_type) => {
  trackEvent(PAYMENT_ANALYTICS_EVENTS.payment_now_click, { ...data, payment_type });
};

export const trackPaymentSuccess = (data, payment_type) => {
  trackEvent(PAYMENT_ANALYTICS_EVENTS.payment_sucess, { ...data, payment_type });
};

export const trackPaymentFailed = (data, payment_type) => {
  trackEvent(PAYMENT_ANALYTICS_EVENTS.payment_failed, { ...data, payment_type });
};

export const trackPaymentVerified = (data, payment_type) => {
  trackEvent(PAYMENT_ANALYTICS_EVENTS.payment_verified, { ...data, payment_type });
};